import React, {useRef, useEffect} from 'react'
import {TweenMax, Power3} from 'gsap'

const Header = () => {
  let logo = useRef(null);

  useEffect(() => {
    TweenMax.to(logo, 1, {css: {visibility:'visible'}})

    TweenMax.from(logo, .5, {y: -50, opacity: 0, ease: Power3.easeIn})
  })

  return (
    <nav className="navbar">
      <h4 ref={el => logo = el}>RETEC</h4>
    </nav>
  )
}

export default Header