import React, {useRef, useEffect, useState} from 'react'
import { useMediaQuery } from 'react-responsive'
import {TweenMax, TimelineLite, Power3} from 'gsap'
// Material-UI
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import {ArrowForward} from '@material-ui/icons'
// Images
import IndexPageImage from 'images/indexPage'
  
const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const ConstructionPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [issue, setIssue] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const subject = `Consulta web: ${issue}`
  
  let app = useRef(null);
  let image = useRef(null);
  let form = useRef(null);
  let tl = new TimelineLite();
  const mobileDevice = useMediaQuery({maxDeviceWidth: 960});
  const desktopDevice = useMediaQuery({minDeviceWidth: 961});

  useEffect(() => {
    TweenMax.to(app, 1, {css: {visibility:"visible"}})
    
    if(window.innerWidth >= 1280) {
      tl.from(image, 1.2, {x: window.innerWidth, opacity: 0, ease: Power3.easeOut}, .5)
        .from(form, .5, {opacity: 0, ease: Power3.easeIn}, .7)
    } else {
      tl.from(image, 1.2, {y: -300, opacity: 0, ease: Power3.easeOut}, .5)
        .from(form, .5, {opacity: 0, ease: Power3.easeIn}, .7)
    }
  },[0]);

  const handleChange = e => {
    const {name, value} = e.target
    if (name === "name" ){
      return setName(value)
    }
    if (name === "email" ){
      return setEmail(value)
    }
    if (name === "company" ){
      return setCompany(value)
    }
    if (name === "issue" ){
      return setIssue(value)
    }
    if (name === "message" ){
      return setMessage(value)
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    const data = {name, email, company, issue, message}
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        "subject": `Consulta web: ${issue}`,
        ...data
      })
    })
    .then(() => {
      setStatus("Form Submission Successful!!");
      setName("");
      setEmail("");
      setCompany("");
      setIssue("");
      setMessage("");
    })
    .catch(error => setStatus("Form Submission Failed!"));
  };

  return (
    <section ref={el => app = el}>
      <Grid container justify="space-between" alignItems="center">
        {
          mobileDevice && 
          <div ref={el => image = el}>
            <IndexPageImage />
          </div>
        }
        <Grid item xs={12} lg={6}>
          <div ref={el => form = el} className="form">
            <h1>
              Sitio en construcción
            </h1>
            <p>
              Actualmente estamos trabajando para mejorar la experiencia que le brindamos, 
              por cualquier consulta déjenos un mensaje y le responderemos lo antes posible. 
              Desde ya muchas gracias.
            </p>
            <form 
              name="contactForm" 
              method="post" 
              data-netlify="true" 
              data-netlify-honeypot="bot-field" 
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contactForm" />
              <input type="hidden" name="subject" value={subject} />
              <TextField 
                onChange={handleChange}
                value={name}
                classes={{root:"leftTF"}}
                type="text"
                name="name"
                label="Nombre Completo"
                margin="dense"
                fullWidth 
                required
              />
              <TextField 
                onChange={handleChange}
                value={email}
                classes={{root:"rightTF"}}
                type="email"
                name="email"
                label="Email" 
                margin="dense"
                fullWidth 
                required
              />
              <TextField 
                onChange={handleChange}
                value={company}
                classes={{root:"leftTF"}}
                type="text"
                name="company"
                label="Empresa" 
                margin="dense"
                fullWidth 
                required
              />
              <TextField
                onChange={handleChange}
                value={issue}
                classes={{root:"rightTF"}}
                type="text"
                name="issue"
                label="Asunto" 
                margin="dense"
                fullWidth 
                required
              />
              <TextField 
                onChange={handleChange}
                value={message}
                classes={{root:"textArea"}}
                type="text"
                name="message"
                label="Mensaje" 
                margin="dense"
                fullWidth 
                multiline
                rows={5}
                required
              />
              <button type="submit">
                ENVIAR <ArrowForward className="icon" />
              </button>
            </form>
          </div>
        </Grid>
        {
          desktopDevice && 
          <Grid item xs={12} lg={6}>
            <div ref={el => image = el}>
              <IndexPageImage />
            </div>
          </Grid>
        }
      </Grid>
    </section>
  )
}

export default ConstructionPage