import React from "react"
// Components
import Layout from "components/Layout"
// Containers
import ConstructionPage from 'containers/IndexPage'

const IndexPage = () => (
  <Layout>
    <ConstructionPage />
  </Layout>
)

export default IndexPage
