import React, {useRef, useEffect} from 'react'
import {TweenMax} from 'gsap'
// Material-UI
import Grid from '@material-ui/core/Grid'

const Footer = () => {
  let footer = useRef(null);

  useEffect(() => {
    TweenMax.to(footer, 1, {css: {visibility:'visible'}})
  });

  return (
    <div className="footer" ref={el => footer = el}>
      <Grid container justify="space-around">
        <Grid item xs={12} lg={5}>
          <h5>RETEC SRL</h5>
          <hr />
          <p>
            Fundada en Octubre del 1987, RETEC es una representante de firmas extranjeras principalmente dedicadas al tratamiento de efluentes, de origen doméstico o industrial. Ofrecemos los equipos de más alta calidad, importados de Estados Unidos, China y Europa. <br /> <br/>
            Brindamos también asesoramiento técnico para selección de los equipos, y para diseño y configuración del sistema a nivel ingenieril. Contamos además con un excelente servicio postventa, avalado por todos nuestros clientes, que significa tranquilidad de estar siempre respaldado.
          </p>
        </Grid>
        <Grid item xs={12} lg={4}>
          <h5>CONTACTO</h5>
          <hr />
          <p>
            <b>Dirección:</b> Juan Parra del Riego 993, Montevideo, Uruguay <br /><br />
            <b>Teléfono:</b> (+598) 2 7070708 <br /><br />
            <b>Mail:</b> administracion@retecsrl.com
          </p>
        </Grid>
      </Grid>
    </div>
  )
}

export default Footer