import React from 'react'
import PropTypes from 'prop-types'
// Components
import Header from 'components/Header'
import Footer from 'components/Footer'
import SEO from 'components/SEO'

const Layout = ({ children }) => {
  return (
    <>
      <SEO 
        title="RETEC SRL" 
        description="Fundada en Octubre del 1987, RETEC es una representante de 
        firmas extranjeras principalmente dedicadas al tratamiento de efluentes, 
        de origen doméstico o industrial."
      />
      <Header />
      <div>
        <main>{children}</main>
      </div>
      <Footer />
      <link href="https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap" rel="stylesheet" />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
